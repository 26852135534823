import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import getLogger from "&js/utils/logger"
import Alpine from "alpinejs"

const log = getLogger("AppState")

function AppState(initObj) {
    const cfgObj = JSON.parse(document.getElementById("app-config").text)
    return {
        init() {
            log.debug("init START")
            this.app = initializeApp(this.config)
            this.analytics = getAnalytics(this.app)
            log.debug("init DONE")
        },
        openModal(options = {}) {
            if (options.clear !== false) {
                document.getElementById("modal_content_container").innerHTML = "<div></div>"
            }
            this.modalBgCss = options?.bgCss || "bg-white"
            this.modalOpen = true
        },
        ...initObj,
        ...cfgObj
    }
}

export default AppState
