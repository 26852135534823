import Alpine from "alpinejs"
import dialog from "@fylgja/alpinejs-dialog"
import AppState from "&js/appState"
import * as htmxEvents from "&js/htmx/eventHandlers"
// import * as account from "&js/account"
import * as charts from "&js/charts"
import * as components from "&js/components"
import * as formFields from "&js/formFields"
import * as property from "&js/property"
import * as maps from "&js/google/maps"
import * as utils from "&js/utils"

import getLogger from "&js/utils/logger"

//
// globalize the good stuff
//
window.getLogger = getLogger
window.htmxEvents = htmxEvents
window.footprint = {
    // account,
    charts,
    components,
    formFields,
    property,
    maps,
    utils
}

// do this last
window.Alpine = Alpine
Alpine.data("AppState", AppState)
Alpine.plugin(dialog)
Alpine.start()
