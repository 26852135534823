/**
 *  get the URL of the referring document.
 *  If that document is external to our site, return undefined
 */
function getPreviousURL() {
    const r = document.referrer
    const u = new URL(location)
    // if referring page is same domain as our app, then redirect to referrer
    if (r && new URL(r).origin == u.origin) return r
    // if no valid document.referrer, try to redirect back to "/app/{org_slug}"
    const p = u.pathname.split("/")
    if (p.length >= 3) {
        return p.slice(0, 3).join("/")
    }
    // otherwise redirect back to "/"
    return "/"
}

export { getPreviousURL }
