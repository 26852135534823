import getLogger from "&js/utils/logger"

const log = getLogger("PlaceSearch")
/**
 * shows a map with a single place Maker
 * responds to @place-change.window events to set the place
 *
 * must be used inside an element that already called x-data="MapsLoader('{{GOOGLE_MAPS_PUBLIC_API_KEY}}')"
 */
function PlaceMap() {
    return {
        showMap: false,
        init() {
            // call async loader
            this.initGoogleMaps()
        },
        async initGoogleMaps() {
            const { Map } = await google.maps.importLibrary("maps")
            this.map = new Map(this.$el, {
                zoom: 11,
                mapTypeControl: true,
                streetViewControl: false,
                fullscreenControl: true,
                zoomControl: true
            })

            const { Marker } = await google.maps.importLibrary("marker")
            this.marker = new Marker({ map: this.map, draggable: false })
        },
        onPlaceChange() {
            this.place = this.$event.detail.place
            this.renderPlace()
        },
        renderPlace() {
            this.marker.setVisible(false)
            if (!this.place) {
                log.debug("no place, HIDING MAP")
                this.showMap = false
                return
            }
            if (!this.place.geometry) {
                window.alert("No details available for '" + this.place.name + "'. Please try again.")
                this.showMap = false
                return
            }
            this.map.setCenter(this.place.geometry.location)
            this.marker.setPosition(this.place.geometry.location)
            this.marker.setVisible(true)
            log.debug("have place, SHOWING MAP")
            this.showMap = true
        }
    }
}

export default PlaceMap
