import getLogger from "&js/utils/logger"
const log = getLogger("Gallery")

function Gallery(initObj) {
    return {
        get imgSrc() {
            return this.items[this.curItem]
        },
        curItem: 0,
        items: [],
        next() {
            this.$event.stopPropagation()
            this.curItem += 1
            if (this.curItem >= this.items.length) {
                this.curItem = 0
            }
            log.debug("next()", { curItem: this.curItem })
        },
        prev() {
            this.$event.stopPropagation()
            this.curItem -= 1
            if (this.curItem < 0) {
                this.curItem = this.items.length ? this.items.length - 1 : 0
            }
            log.debug("prev()", { curItem: this.curItem })
        },
        ...initObj
    }
}

export default Gallery
