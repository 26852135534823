/**
 * Global Logger, to be used client-side
 *
 * To use:
 *
 *     ```
 *     // example file: myCoolFunctions.js
 *
 *     import getLogger from static/js/logger
 *
 *     log = getLogger("myCoolFunctions")
 *     log.debug("say","what?")
 *     ```
 *
 * Global log level is stored in localstorage via `Logger.level`.  To get/set the global log level:
 *
 *     ```
 *     import { Logger } from static/js/logger
 *
 *     Logger.setLevel("warn") // defaults to all logs enabled
 *     ```
 *
 * You can also turn specific namespaces off, stored in localStorage via `Logger.dontLog`:
 *
 *     ```
 *     import { Logger } from static/js/logger
 *
 *     // block all messages from myCoolFunctions
 *     Logger.dontLog("myCoolFunctions")
 *
 *     // block all messages from someModule and otherStuff and ...
 *     // myCoolFunctions will still be blocked from above
 *     Logger.dontLog("someModule", "otherStuff", ...)
 *
 *     // log everything again
 *     Logger.resetDont()
 *     ```
 */
const Logger = {
    // Shrink text to 2048 max length
    shrink(s) {
        if (s.length > 2048 && s.substr) {
            return s.substr(0, 2047) + "…" + s.slice(-1)
        }
        return s
    },
    // Dump objects to string, and shrink to 2048 chars max
    stringify(s) {
        return this.shrink(JSON.stringify(s, this.getCircularReplacer()))
    },
    // prevent TypeError: cyclic object value
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
    getCircularReplacer() {
        const ancestors = []
        return function (key, value) {
            if (typeof value !== "object" || value === null) {
                return value
            }
            // `this` is the object that value is contained in,
            // i.e., its direct parent.
            while (ancestors.length > 0 && ancestors.at(-1) !== this) {
                ancestors.pop()
            }
            if (ancestors.includes(value)) {
                return "[Circular]"
            }
            ancestors.push(value)
            return value
        }
    },
    newlineRe: new RegExp("\n", "g"),
    processArgs(args) {
        for (let i = 0; i < args.length; i++) {
            if (args[i] === undefined) {
                args[i] = "undefined"
            } else if (args[i] === null) {
                args[i] = "null"
            } else if (args[i] instanceof Function) {
                args[i] = this.shrink(args[i].toString().replace(this.newlineRe, " "))
            } else if (args[i] instanceof Error) {
                // Error.stack not supported in IE Edge
                // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack#Browser_compatibility
                if (args[i].stack) {
                    args[i] = args[i].stack.replace(this.newlineRe, "")
                }
                args[i] = this.shrink(args[i])
            } else if (args[i] instanceof Element) {
                // log DOM Elements as HTML
                args[i] = this.shrink(args[i].outerHTML)
            } else if (args[i] instanceof Object) {
                // note for later https://developer.mozilla.org/en-US/docs/Web/API/Console/log#logging_objects
                args[i] = this.stringify(args[i])
            } else if (args[i].length > 256) {
                args[i] = this.shrink(args[i])
            }
        }
        return args.join(" ")
    },
    // format timestamp as local-time ISO string with timezone offset
    getTimestamp() {
        // https://www.30secondsofcode.org/js/s/to-iso-string-with-timezone
        const d = new Date()
        const tzOffset = -d.getTimezoneOffset()
        const sign = tzOffset >= 0 ? "+" : "-"
        const pz = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, "0")
        // https://measurethat.net/Benchmarks/Show/195/1/string-interpolation-vs-concatenation
        return (
            d.getFullYear() +
            "-" +
            pz(d.getMonth() + 1) +
            "-" +
            pz(d.getDate()) +
            "T" +
            pz(d.getHours()) +
            ":" +
            pz(d.getMinutes()) +
            ":" +
            pz(d.getSeconds()) +
            sign +
            pz(tzOffset / 60) +
            ":" +
            pz(tzOffset % 60)
        )
    },
    dontLogKey: "Logger.dontLog",
    dontLog(...namespace) {
        var items = localStorage.getItem(this.dontLogKey)
        if (!items) {
            items = []
        }
        items = items.concat(namespace)
        localStorage.setItem(this.dontLogKey, items)
    },
    resetDont() {
        localStorage.removeItem(this.dontLogKey)
    },
    shouldLog(namespace, level) {
        const items = localStorage.getItem(this.dontLogKey)
        if (items && items.indexOf(namespace) >= 0) {
            // return false if namespace is in dontLog
            return false
        }
        if (level) {
            if (level == "error") {
                // always log error
                return true
            }
            // alphabetically, "warn" > "info" > "debug"
            return level > this.getLevel()
        }
        return true
    },
    levelKey: "Logger.level",
    setLevel(level) {
        localStorage.setItem(this.levelKey, level)
    },
    getLevel(level) {
        return localStorage.getItem(this.levelKey) || ""
    },
    log(namespace, level, ...args) {
        if (this.shouldLog(namespace, level)) {
            // TODO - add Sentry logging here for errors
            const l = level == "warning" ? console.warn : console[level]
            l("%c%s", this.levelStyle(level), this.getMessage(namespace, level, ...args))
        }
    },
    // format the log message
    getMessage(namespace, level, ...args) {
        return "[" + this.getTimestamp() + "] " + level.toUpperCase() + " " + namespace + " " + this.processArgs(args)
    },
    levelStyle(level) {
        // https://chrisyeh96.github.io/2020/03/28/terminal-colors.html
        switch (level) {
            case "debug":
                return "color:#8ae234"
            case "info":
                return "color:#32afff"
            case "warning":
                return "color:orange"
        }
        return ""
    }
}

/**
 * getLogger returns debug, dir, info, warning and error functions to log into console.
 *
 * @param {String} namespace category for log messages
 */
const getLogger = function getLogger(namespace) {
    return {
        debug: (...args) => {
            Logger.log(namespace, "debug", ...args)
        },
        dir: (obj, ...args) => {
            if (Logger.shouldLog(namespace, "debug")) {
                Logger.log(namespace, "debug", ...args)
                console.dir(obj)
            }
        },
        info: (...args) => {
            Logger.log(namespace, "info", ...args)
        },
        warn: (...args) => {
            Logger.log(namespace, "warn", ...args)
        },
        warning(...args) {
            this.warn(...args)
        },
        error: (...args) => {
            Logger.log(namespace, "error", ...args)
        }
    }
}

// //
// // simple test
// //
// getLogger("logger").debug(
//     "a",
//     3,
//     -2.719,
//     Number.parseFloat(-1234567.89).toExponential(4),
//     parseFloat("nope!"),
//     { obj: "poppo" },
//     function getSome() {
//         alert("oh!")
//     },
//     true,
//     false,
//     null,
//     undefined,
//     "ALL GOOD"
// )

export { Logger }
export default getLogger
