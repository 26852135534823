import getLogger from "&js/utils/logger"
const log = getLogger("Dropdown")

function DropdownMenu(initObj) {
    return {
        name: "dropdown-menu",
        open: false,
        clearOnOpen: false,
        /** html attributes for top level DOM Element via `x-bind="attrs()"` */
        attrs() {
            let attrs = {
                "x-show": "open",
                "@click.outside": "close()",
                [`@${this.name}-open.window`]: "openDropdown()"
            }
            log.debug(attrs)
            return attrs
        },
        init() {
            this.defaultHTML = this.$root.innerHTML
            // log.debug("init", { name: this.name })
        },
        openDropdown() {
            // log.debug("openDropdown")
            // clear the contents when opening
            if (this.clearOnOpen) {
                this.$root.innerHTML = this.defaultHTML
            }
            this.open = true
        },
        close() {
            // log.debug("close")
            this.open = false
        },
        toggleMenu() {
            if (this.open) {
                this.close()
            } else {
                this.openDropdown()
            }
        },
        ...initObj
    }
}

export default DropdownMenu
