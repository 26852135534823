import getLogger from "&js/utils/logger"
import Autosubmit from "./autosubmit"
import ky from "ky"

const log = getLogger("SelectField")

function SelectField(initObj) {
    return {
        init() {
            if (!this.name) {
                // we need a name for event dispatching
                this.name = "select-field"
            }
            this.initSelectedIndex()
            // update activeDescendent for aria setting
            this.$watch("activeIndex", (e) => {
                this.open &&
                    (null !== this.activeIndex
                        ? (this.activeDescendant = this.$refs.selectOptions.children[this.activeIndex].id)
                        : (this.activeDescendant = ""))
            })
            log.debug("init DONE")
        },
        initSelectedIndex() {
            _choose = (v) => {
                return this.choose(
                    this.items.findIndex((item) => item[0] === v),
                    true
                )
            }
            if (this.name) {
                // initialize from a hidden input field, if there is one
                var e = document.getElementsByName(this.name)[0]
                if (e && e.value) {
                    return _choose(e.value)
                }
            }
            // initialize to value
            if (this.initValue) {
                return _choose(this.initValue)
            }
        },
        activeDescendant: null,
        open: false,
        activeIndex: null,
        selectedIndex: 0,
        items: [],
        lasVal: "",
        curVal: "",
        get active() {
            return this.items[this.activeIndex]
        },
        get value() {
            if (this.selectedIndex !== null) {
                return this.items[this.selectedIndex][0]
            }
        },
        // selects an item by ##
        choose(i, init = false) {
            var changed = false
            if (i === undefined || i === null || i < 0 || i >= this.items.length) {
                // don't select invalid item number
                if (this.selectedIndex !== null) {
                    changed = true
                }
                this.selectedIndex = null
            } else if (this.selectedIndex != i) {
                changed = true
                this.selectedIndex = i
            }
            log.debug(`choose(${this.selectedIndex})`, "changed=" + changed)

            // don't close or fire change event when choosing from init()
            if (!init) {
                this.close()
                if (changed) {
                    if (this.onChange) {
                        this.onChange()
                    }
                    const en = this.name + "_change"
                    log.debug("dispatch ", en)
                    this.$dispatch(en, this)
                    this.emitAutosubmit()
                }
            }
        },
        close() {
            this.open = false
            this.$refs.button.focus()
            this.$dispatch(this.name, this)
        },
        onButtonClick() {
            log.debug("onButtonClick")
            if (!this.items.length && this.itemsUrl) {
                // execute AJAX request
                log.debug("fetch ", this.itemsUrl)
                ky(this.itemsUrl)
                    .json()
                    .then((data) => {
                        this.items = data
                        log.debug(this.items)
                        if (!this.items.length) {
                            this.items = [[null, "No results"]]
                        }
                        this.initSelectedIndex()
                        this.openMenu()
                    })
                // TODO openMenu and show animation while loading; error handling
            } else {
                this.openMenu()
            }
        },
        openMenu() {
            if (!this.open) {
                this.activeIndex = this.selectedIndex
                this.open = true
                this.$nextTick(() => {
                    this.$refs.selectOptions.focus()
                    log.debug("dispatch", this.name)
                    this.$dispatch(this.name, this)
                    if (this.$refs.selectOptions.children[this.activeIndex]) {
                        this.$refs.selectOptions.children[this.activeIndex].scrollIntoView({
                            block: "nearest"
                        })
                    }
                })
            }
        },
        toggleMenu() {
            if (this.open) {
                this.close()
            } else {
                this.onButtonClick()
            }
        },
        onEscape() {
            this.close()
        },
        onArrowUp() {
            if (this.activeIndex) {
                this.activeIndex--
            }
            this.$refs.selectOptions.children[this.activeIndex].scrollIntoView({
                block: "nearest"
            })
        },
        onArrowDown() {
            this.activeIndex++
            if (this.activeIndex >= this.items.length) {
                this.activeIndex = this.items.length - 1
            }
            this.$refs.selectOptions.children[this.activeIndex].scrollIntoView({
                block: "nearest"
            })
        },
        ...Autosubmit(initObj),
        ...initObj
    }
}

export default SelectField
