import getLogger from "&js/utils/logger"
const log = getLogger("Collapsible")

/** adds a show/hide toggle link and gradient fade if content exceeds maxHeight value */
function Collapsible(initObj) {
    return {
        contentHeight: 0,
        maxHeight: 150,
        arrowUp: "▴",
        arrowDwn: "▾",
        showMoreClass: "text-teal-400 block text-center cursor-pointer hover:bg-gray-50 mb-1",
        open: false,
        init() {
            this.maxHeight = cleanSize(this.maxHeight)
            log.debug("init", { maxHeight: this.maxHeight, contentHeight: this.contentHeight, tooLong: this.tooLong })
        },
        get contentHeight() {
            return this.$refs.heightCalculator.clientHeight
        },
        get tooLong() {
            return this.contentHeight > this.maxHeight
        },
        get style() {
            return this.tooLong
                ? this.open
                    ? { height: `${this.contentHeight}px` }
                    : {
                          height: `${this.maxHeight}px`
                      }
                : {}
        },
        get gradientStyle() {
            let s = this.open ? { visibility: "hidden", opacity: 0 } : { visibility: "visible", opacity: 1 }
            Object.assign(s, this.style)
            return s
        },
        toggle() {
            this.open = !this.open
        },
        get toggleLinkTxt() {
            return this.open ? `Collapse ${this.arrowUp}` : `Expand ${this.arrowDwn}`
        },
        ...initObj
    }
}

/**
 * if val is a String, convert it to a number of pixels
 */
function cleanSize(val) {
    if (typeof val === "string" || val instanceof String) {
        valStr = val
        val = Number(valStr.replace(/[^0-9.]/g, ""))
        if (valStr.endsWith("rem")) {
            val = rem2Px(val)
        }
    }
    return val
}

// https://stackoverflow.com/a/42769683
function rem2Px(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export default Collapsible
