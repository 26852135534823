import getLogger from "&js/utils/logger"
import ky from "ky"

const log = getLogger("AddPropertyForm")

/** animates the steps while waiting for property creation */
function progressDialog(initObj) {
    return {
        thinking: true,
        ready: false,
        errored: false,
        items: [],
        timeouts: [],
        url: "",
        /** html attributes for top level DOM Element */
        attrs: {
            "x-show": "processing",
            "@htmx-err.window": "errored=true",
            "@htmx:before-on-load.window.prevent": "url=$event.detail.xhr.getResponseHeader('HX-Redirect')"
        },
        init() {
            if (this.processing) {
                this.$refs["dialog"].show()
            }
            // change labels to objects
            for (var i = 0; i < this.items.length; i++) {
                this.items[i] = { txt: this.items[i], w: 0, c: false }
            }
            // watch processing value from parent to trigger animation
            this.$watch("processing", (p) => {
                p && this.animate()
            })
            // watch if we got an http error and stop animations if so
            this.$watch("errored", (v) => {
                v && this.clearTimeouts()
            })
        },
        destroy() {
            this.clearTimeouts()
        },
        clearTimeouts() {
            this.timeouts.forEach((x) => clearTimeout(x))
            this.timeouts = []
        },
        reset() {
            this.clearTimeouts()
            this.items.forEach((i) => (i.w = 0))
            this.thinking = true
            this.ready = false
        },
        animate() {
            if (this.ready) {
                this.reset()
            }
            for (var i = 0; i < this.items.length; i++) {
                // progress bar
                this.timeouts[2 * i] = setTimeout(
                    function () {
                        this.w = 100
                    }.bind(this.items[i]),
                    2000 * i + 1000
                )
                // checkmark
                this.timeouts[2 * i + 1] = setTimeout(
                    function () {
                        this.c = true
                    }.bind(this.items[i]),
                    2000 * i + 2000
                )
            }
            // thinking... button and ready state
            this.timeouts[2 * this.items.length] = setTimeout(() => {
                this.thinking = false
                this.ready = this.url && true
                if (!this.ready) {
                    this.timeouts[2 * this.items.length + 1] = setTimeout(() => {
                        // wait 5 more seconds to see if we get url back
                        this.ready = this.url && true
                        if (!this.ready) {
                            // timeout the request
                            this.errored = true
                        }
                    }, 5000)
                }
            }, 2000 * this.items.length + 2000)
        },
        ...initObj
    }
}

/**
 * builds placeData for add property form submission
 *
 * @param {p} - place from google.maps autocomplete.getPlace()
 * @returns placeData for add property form submission
 */
function getPlaceData(p) {
    var pd = {
        place_id: p.place_id,
        name: p.name,
        formatted_address: p.formatted_address,
        adr_address: p.adr_address
    }
    // ** convert adr_address address parts from html to json **
    // ---------------------------------------------------------
    // example: '<span class="street-address">48 Pirrama Rd</span>, <span class="locality">Pyrmont</span> <span class="region">NSW</span> <span class="postal-code">2009</span>, <span class="country-name">Australia</span>',
    // returns: { street_address: '48 Pirrama Rd', locality: "Pyrmont", region: "NSW", postal_code: '2009', country_name: 'Australia' }
    //
    // NOTE: This is now handled on the server in app/svcs/gmapsvc.py PlaceData model
    //
    // Object.assign(
    //     pd,
    //     ...Array.from(new DOMParser().parseFromString(p.adr_address, "text/html").body.children).map((child) => {
    //         return { [child.className.replace("-", "_")]: child.innerText }
    //     })
    // )
    return pd
}

/**
 * top level component for add_property.jhtml form
 */
function addPropertyForm(initObj) {
    return {
        showBtn: false,
        processing: false, // used by child progressDialog
        /** html attributes for <form> tag */
        attrs() {
            return {
                "hx-post": this.action,
                "hx-trigger": "autosubmit",
                "@place-change": "onPlaceChange"
            }
        },
        /** receive a place-change event from maps.PlaceSearch and create placeData for form submission */
        onPlaceChange() {
            const p = this.$event.detail.place
            if (!p) {
                this.reset()
            } else {
                this.placeData = getPlaceData(p)
                this.showBtn = true
            }
        },
        reset() {
            this.placeData = null
            this.showBtn = false
        },
        async submit() {
            // set form data to this.placeData via https://htmx.org/attributes/hx-vals/
            this.$root.setAttribute("hx-vals", JSON.stringify(this.placeData))
            // submit form
            log.debug("submitting", this.$root.getAttribute("hx-post"))
            // autosubmit triggers form submission via hx-trigger https://htmx.org/attributes/hx-trigger/
            this.$dispatch("autosubmit")
            // show process dialog ($watch()'ed in progressDialog)
            this.processing = true
            // progressDialog will link to new property via htmx redirect https://htmx.org/reference/#response_headers
            // htmx.eventHandlers will show error if form submission was unsuccessful
        },
        Dialog(dInitObj) {
            return progressDialog(dInitObj)
        },
        ...initObj
    }
}

export default addPropertyForm
