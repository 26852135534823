import { Chart, LineController, LineElement, LinearScale, CategoryScale, PointElement } from "chart.js"

import getLogger from "&js/utils/logger"

const log = getLogger("CashFlowChart")
const USD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0
})

function CashFlowChart(initObj) {
    return {
        table: null,
        chart: null,
        init() {
            log.debug("init START")
            const c = this.cumulative()
            this.chart = new Chart(this.$refs["chart"], {
                type: "line",
                data: {
                    labels: this.labels(c),
                    datasets: this.datasets(c)
                },
                options: this.options()
            })
            log.debug("init DONE")
        },
        cumulative() {
            var c = []
            this.table.forEach((row, i) => {
                const lc = i && c[i - 1]
                c[i] = {
                    avg: row.avg + (lc && lc.avg),
                    optimistic: row.low_cost_high_savings + (lc && lc.optimistic),
                    pessimistic: row.high_cost_low_savings + (lc && lc.pessimistic)
                }
            })
            return c
        },
        labels(c) {
            return c.map((r, i) => (i ? `Year ${i}` : "Upfront"))
        },
        datasets(c) {
            return [
                {
                    label: "Optimistic",
                    data: c.map((r) => r.optimistic),
                    borderColor: "#14B8A6", // text-teal-500
                    backgroundColor: "#14B8A6", // text-teal-500
                    pointHitRadius: 20
                },
                {
                    label: "Avg",
                    data: c.map((r) => r.avg),
                    borderColor: "rgb(132 204 22)", // rgb(128,184,166)", // middle color between text-teal-500 and text-pink-500
                    backgroundColor: "rgb(132 204 22)", //rgb(128,184,166)", // middle color between text-teal-500 and text-pink-500
                    fill: {
                        target: "-1",
                        below: "rgba(204, 251, 241, 0.5)" // text-teal-100
                    },
                    pointHitRadius: 20
                },
                {
                    label: "Pessimistic",
                    data: c.map((r) => r.pessimistic),
                    borderColor: "rgb(236, 72, 153)", // text-pink-500
                    backgroundColor: "rgb(236, 72, 153)", // text-pink-500
                    fill: {
                        target: "-1",
                        above: "rgba(252, 231, 243, 0.5)", // text-pink-100
                        below: "rgba(252, 231, 243, 0.5)" // text-pink-100
                    },
                    pointHitRadius: 20
                }
            ]
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                resizeDelay: 100,
                plugins: {
                    legend: {
                        display: true
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || ""

                                if (label) {
                                    label += ": "
                                }
                                if (context.parsed.y !== null) {
                                    label += USD.format(context.parsed.y)
                                }
                                return label
                            }
                        }
                    }
                },
                tooltip: {
                    mode: "index",
                    intersect: false
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return USD.format(value)
                            }
                        }
                    }
                }
            }
        },
        ...initObj
    }
}

export default CashFlowChart
