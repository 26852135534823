import getLogger from "&js/utils/logger"

const log = getLogger("htmxEventHandlers")

function htmx_networkErr(evt) {
    htmx_showError("A network error occured")
    if (evt.detail && evt.detail.exception) {
        log.error(evt.detail.exception)
    }
}

/**
 * builds error message string from htmx event
 * usually in the form of:
 *
 *     "{http_code} {response_message}"
 *
 * will log error and return "Unknown error"
 * if it cannot make a message from evt
 */
function htmx_errMsg(evt) {
    var errMsg = ""
    var xhr = evt.detail && evt.detail.xhr
    if (xhr) {
        // console.dir(xhr)
        if (xhr.status) {
            errMsg += xhr.status + " "
        }
        if (xhr.statusText) {
            errMsg += xhr.statusText
        } else {
            errMsg += "A server error occured"
        }
    } else {
        log.error("Could not create error message from ", evt)
        errMsg = "Unknown error"
    }
    return errMsg
}

/**
 * Given an htmx event, searches under e.target for a DOM node
 * with .htmx-err class
 *
 * Otherwise, returns document.getElementById("htmx_err")
 */
function htmx_findErrDiv(e) {
    // console.dir(e.target)
    if (e && e.target && e.target.tagName == "FORM") {
        // if this htmx request had targeted a form, check
        // to see if there's an htmx_errDiv inside that form
        const l = e.target.querySelector(".htmx-err")
        // console.dir(l)
        if (l) {
            return l
        }
    }
    // otherwise search the whole dom for id
    return document.getElementById("htmx_err")
}

/**
 * Given an htmx event, searches under e.target for a DOM node
 * with .htmx-err-container class
 *
 * Otherwise, returns document.getElementById("htmx_err_container")
 *
 * If neither of those are found, returns htmx_findErrDiv(e)
 */
function htmx_findErrContainer(e) {
    // console.dir(e.target)
    if (e && e.target && e.target.tagName == "FORM") {
        // if this htmx request had targeted a form, check
        // to see if there's an htmx_errDiv inside that form
        const l = e.target.querySelector(".htmx-err-container")
        if (l) {
            return l
        }
    }
    // otherwise search the whole dom for id
    const m = document.getElementById("htmx_err_container")
    if (m) {
        return m
    }
    return htmx_findErrDiv(e)
}

/**
 * given an htmx event or a string shows it
 * in the error div #htmx_err, and
 * scrolls to #htmx_err_container if it's out of view
 *
 * if this div doesn't exist in the dom, no
 * error will be displayed
 *
 * if no msg, or the event is marked successful,
 * the #htmx_err_container div is hidden
 */
function htmx_showError(msg) {
    // were we passed an htmx event?
    // log.dir(msg, "htmx_showError msg==>")
    var detail = msg && msg.detail
    if (detail) {
        if (detail.successful) {
            msg = false
        } else if (detail.failed) {
            msg = htmx_errMsg(msg)
        } else if (detail.error) {
            msg = "A network error occured"
        }
    }
    const errDiv = htmx_findErrDiv(detail)
    const errContainer = htmx_findErrContainer(detail)
    if (msg) {
        if (typeof msg === "string") {
            dispatchEvent(new CustomEvent("htmx-err", { detail: { msg } }))
            if (!errDiv || !errContainer) {
                log.error("Page missing #htmx_err for errMsg ", msg)
            } else {
                log.error("showing errMsg", msg)
                errDiv.innerText = msg
                errContainer.style.display = "block"
                // check if errContainer is in view
                function isInView(el) {
                    const r = el.getBoundingClientRect()
                    return (
                        r.top >= 0 &&
                        r.left >= 0 &&
                        r.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        r.right <= (window.innerWidth || document.documentElement.clientWidth)
                    )
                }
                if (!isInView(errContainer)) errContainer.scrollIntoView({ behavior: "smooth" })
            }
        } else {
            // this happens on hx-redirects
            // log in case we need to understand where else it may occur
            // log.warn("could not construct errMsg for event", msg)
        }
    } else if (errDiv) {
        // request was successful - hide the error div!
        errContainer.style.display = "none"
    }
}

/**
 * will cancel an htmx event if it is cancellable
 *
 * helpful to stop processing and troubleshoot issues
 */
function htmx_cancel(evt) {
    return evt.preventDefault()
}

htmx.on("htmx:onLoadError", htmx_networkErr)
htmx.on("htmx:onSendError", htmx_networkErr)
// htmx.on("htmx:responseError", htmx_showError); <-- handled by afterRequest
htmx.on("htmx:afterRequest", htmx_showError)

export { htmx_showError as showError }
