import getLogger from "&js/utils/logger"

const log = getLogger("PlaceSearch")

function PlaceSearch() {
    return {
        init() {
            this.initAutocomplete()
        },
        async initAutocomplete() {
            const { Autocomplete } = await google.maps.importLibrary("places")

            this.autocomplete = new Autocomplete(this.$el, {
                fields: ["name", "formatted_address", "geometry", "adr_address", "business_status", "place_id"],
                types: ["address"],
                componentRestrictions: { country: ["us", "ca"] }
            })
            this.listener = this.autocomplete.addListener("place_changed", () => {
                const place = this.autocomplete.getPlace()
                log.debug("dispatch place-change", place)
                this.$dispatch("place-change", { place })
            })
        },
        onChange() {
            if (!this.$el.value) {
                log.debug("dispatch place-change NULL")
                this.$dispatch("place-change", { place: null })
            }
        }
    }
}

export default PlaceSearch
