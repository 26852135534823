function Autosubmit(initObj) {
    return {
        autosubmit: initObj.autosubmit || false,
        /**
         * emits "autosubmit" event
         */
        emitAutosubmit() {
            if (this.autosubmit) {
                // delay autosubmit slightly to allow form values to update
                this.$nextTick(() => {
                    this.$dispatch("autosubmit")
                })
            }
        }
    }
}

export default Autosubmit
